<template>
  <section-wrapper class="newsletter-form__container">
    <h2 class="section__title">
      {{ data.title }}
    </h2>

    <div class="section__description" v-html="data.description" />

    <form-wrapper class="form__container">
      <input-wrapper
        :error="!!v$.email.$errors.length"
        :input-id="NEWSLETTER_FORM_FIELDS.EMAIL"
        class="input__container"
        required
      >
        <template #label>{{ $t("formFields.labels.email") }}</template>
        <template #error>{{ v$["email"].$errors?.[0].$message }}</template>

        <field-input
          v-model="state.email"
          :error="!!v$.email.$errors.length"
          :field-id="NEWSLETTER_FORM_FIELDS.EMAIL"
          :placeholder="$t('formFields.placeholders.email')"
          autocomplete="email"
          @keyup.enter="handleSubmit"
        />
      </input-wrapper>

      <template #footer>
        <app-button
          :loading="loading"
          class="app-button"
          variant="primary"
          @click="handleSubmit"
        >
          {{ $t("signUp") }}
        </app-button>
      </template>

      <template v-if="success" #success-message>
        {{ $t("newsletter.success") }}
      </template>

      <template v-if="!!error" #error-message>
        {{ $t(error) }}
      </template>
    </form-wrapper>
  </section-wrapper>
</template>

<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import type { FetchError } from "ofetch";
import type { Ref } from "vue";
import { useErrorTracking } from "~/modules/error/composables/useErrorTracking";
import AppButton from "~/modules/shared/components/AppButton.vue";
import FieldInput from "~/modules/shared/components/form/FieldInput.vue";
import FormWrapper from "~/modules/shared/components/form/FormWrapper.vue";
import InputWrapper from "~/modules/shared/components/form/InputWrapper.vue";
import {
  NEWSLETTER_CONTACT_TAG,
  NEWSLETTER_FORM_FIELDS,
} from "~/modules/shared/components/newsletterForm/NewsletterForm.constants";
import SectionWrapper from "~/modules/shared/components/SectionWrapper.vue";
import { useNewsletterSignUp } from "~/modules/shared/composables/useNewsletterSignUp/useNewsletterSignUp";
import { CLIENT_ERROR_SLUGS } from "~/modules/shared/constants/ClientErrorSlugs";
import type { NewsletterForm } from "~/modules/shared/ts/pageComponents/NewsletterForm";

defineProps<{
  data: NewsletterForm;
}>();

const { trackError } = useErrorTracking();
const { error, loading, success, submitNewsletterSignUp } =
  useNewsletterSignUp();

const state = reactive({
  email: "",
});
const rules = {
  email: {
    email,
    required,
  },
};

const v$ = useVuelidate(rules, state);

const handleSubmit = async () => {
  v$.value.$touch();

  if (v$.value.$error) return;

  try {
    await submitNewsletterSignUp({
      email: state.email,
      tags: [NEWSLETTER_CONTACT_TAG],
    });
    v$.value.$reset();
  } catch (err) {
    if ((err as Ref<FetchError>).value?.statusCode === 422) {
      error.value = "newsletter.errorExists";
    } else {
      error.value = "newsletter.error";
    }
    trackError(CLIENT_ERROR_SLUGS.NEWSLETTER_SIGN_UP, err);
  }
};
</script>

<style lang="scss" scoped>
.newsletter-form__container {
  @apply text-body-xs;

  .section__title {
    @apply mb-14 text-title-md;
  }

  .section__description {
    @apply w-full md:w-4/5 xl:w-3/4 mb-14 text-primary-400;
  }

  .form__container {
    @apply w-full lg:w-2/3 xl:w-1/2 md:flex-row md:items-center;

    .input__container {
      @apply flex-1;
    }

    .app-button {
      @apply md:ml-10 mt-10;
    }
  }
}
</style>
